/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/


import { createSSRApp, createApp } from 'vue'
import { isRunningOnPWA } from './ssr-pwa'







import '@quasar/extras/material-icons/material-icons.css'



import '@quasar/extras/animate/slideInRight.css'

import '@quasar/extras/animate/fadeOut.css'


// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'




import 'src/css/app.scss'


import createQuasarApp from './app.js'
import quasarUserOptions from './quasar-user-options.js'


import 'app/src-pwa/register-service-worker'







// Needed only for iOS PWAs
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.navigator.standalone) {
  import(/* webpackChunkName: "fastclick"  */ '@quasar/fastclick')
}


const publicPath = `/`


async function start ({ app, router, store, storeKey }, bootFiles) {
  
  // prime the store with server-initialized state.
  // the state is determined during SSR and inlined in the page markup.
  if (isRunningOnPWA !== true && window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }
  

  
  let hasRedirected = false
  const getRedirectUrl = url => {
    try { return router.resolve(url).href }
    catch (err) {}

    return Object(url) === url
      ? null
      : url
  }
  const redirect = url => {
    hasRedirected = true

    if (typeof url === 'string' && /^https?:\/\//.test(url)) {
      window.location.href = url
      return
    }

    const href = getRedirectUrl(url)

    // continue if we didn't fail to resolve the url
    if (href !== null) {
      window.location.href = href
      
    }
  }

  const urlPath = window.location.href.replace(window.location.origin, '')

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    try {
      await bootFiles[i]({
        app,
        router,
        store,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        redirect(err.url)
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  app.use(router)
  app.use(store, storeKey)

  
    
      if (isRunningOnPWA === true) {
        
        app.mount('#q-app')
      }
      else {
    
    // wait until router has resolved all async before hooks
    // and async components...
    router.isReady().then(() => {
      
      app.mount('#q-app')
    })
    
    }
    

  

}

createQuasarApp(isRunningOnPWA ? createApp : createSSRApp, quasarUserOptions)

  .then(app => {
    return Promise.all([
      
      import(/* webpackMode: "eager" */ 'boot/sentry'),
      
      import(/* webpackMode: "eager" */ 'boot/google-analytics'),
      
      import(/* webpackMode: "eager" */ 'boot/i18n_client.js'),
      
      import(/* webpackMode: "eager" */ 'boot/axios'),
      
      import(/* webpackMode: "eager" */ 'boot/general'),
      
      import(/* webpackMode: "eager" */ 'boot/firebase')
      
    ]).then(bootFiles => {
      const boot = bootFiles
        .map(entry => entry.default)
        .filter(entry => typeof entry === 'function')

      start(app, boot)
    })
  })


<template>
  <router-view v-slot="{ Component }">
    <transition name="transitions" enter-active-class="animated slideInRight" leave-active-class="animated fadeOut" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useMeta, LocalStorage, useQuasar } from 'quasar'
import { useRoute } from 'vue-router'

export default {
  name: 'App',

  setup () {
    let { locale, t } = useI18n({ useScope: 'global' })

    const route = useRoute()
    const $q = useQuasar()

    // Set the language based on the route, localStorage, locale or browser language
    let language =
      route.params.language ||
      LocalStorage.getItem('locale') ||
      Intl?.DateTimeFormat()?.resolvedOptions()?.locale ||
      $q.lang.getLocale()
    language = language?.replace('_', '')?.toLowerCase()

    const languages = {
      // Supported routes
      en: 'en-US',

      ng: 'en-NG',
      gh: 'en-NG',
      cm: 'en-NG',
      lr: 'en-NG',

      pt: 'pt-BR',
      br: 'pt-BR',

      de: 'de',
      ja: 'ja',
      cn: 'zh-CN',
      fr: 'fr',
      es: 'es',

      // Languages from browser locale and browser language
      'en-gb': 'en-US',

      'en-ng': 'en-NG',
      'en-gh': 'en-NG',
      'en-cm': 'en-NG',
      'en-lr': 'en-NG',

      'de-at': 'de',
      'de-ch': 'de',

      'fr-fr': 'fr',
      'fr-ca': 'fr',
      'fr-be': 'fr',

      'pt-br': 'pt-BR',
      'pt-pt': 'pt-BR',

      'es-419': 'es',
      'es-es': 'es',
      'es-us': 'es',
      'es-mx': 'es',
      'es-ar': 'es',
      'es-cl': 'es',
      'es-co': 'es',
      'es-pe': 'es',
      'es-py': 'es',
      'es-uy': 'es',
      'es-cr': 'es',
      'es-ec': 'es',
      'es-gt': 'es',
      'es-xl': 'es',
      'es-hn': 'es',
      'es-sv': 'es',
      'es-pa': 'es',
      'es-bo': 'es',
      'es-la': 'es',
      'es-ve': 'es',
      'es-do': 'es',
      'es-cu': 'es',
      'es-ni': 'es',
      'es-br': 'es',

      'zh-cn': 'zh-CN',
      'zh-tw': 'zh-CN',
      'zh-hans-cn': 'zh-CN',
      'zh-my': 'zh-CN',
      'zh-sg': 'zh-CN',
      'zh-us': 'zh-CN',

      'ja-jp': 'jp'
    }
    locale.value = languages[language] || 'en-US'

    const metaData = {
      title: `${t('title')} — ${t('homepage_text')}`,
      // meta tags
      meta: {
        description: { name: 'description', content: t('homepage_subtext') },
        keywords: { name: 'keywords', content: 'download, download twitter, download instagram, download facebook' }
      }
    }
    useMeta(metaData)

    return {}
  }
}
</script>

<style>
#q-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px;
}

body > div.q-dialog.fullscreen.no-pointer-events > div.q-dialog__inner.flex.no-pointer-events.q-dialog__inner--minimized.q-dialog__inner--bottom.fixed-bottom.justify-center > div {
  color: #2c3e50;
  font-size: 14px;
}

.text-primary-text {
  color: #2c3e50;
}
</style>

/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/en-US'



import {QAvatar,QBanner,QBtn,QCard,QImg,QCardActions,QCardSection,QDrawer,QHeader,QIcon,QInfiniteScroll,QInput,QItem,QItemLabel,QItemSection,QLayout,QList,QPage,QPageContainer,QSeparator,QSpinner,QSpinnerDots,QToolbar,QToolbarTitle,QVideo,QDialog,QForm,QToggle,QSelect,Ripple,ClosePopup,Meta,Notify,Dialog,Loading,BottomSheet} from 'quasar'



export default { config: {"dark":false},lang,components: {QAvatar,QBanner,QBtn,QCard,QImg,QCardActions,QCardSection,QDrawer,QHeader,QIcon,QInfiniteScroll,QInput,QItem,QItemLabel,QItemSection,QLayout,QList,QPage,QPageContainer,QSeparator,QSpinner,QSpinnerDots,QToolbar,QToolbarTitle,QVideo,QDialog,QForm,QToggle,QSelect},directives: {Ripple,ClosePopup},plugins: {Meta,Notify,Dialog,Loading,BottomSheet} }


import { boot } from 'quasar/wrappers'

export default boot(({ app }) => {
  app.mixin({
    data () {
      return {
        appVersion: null,
        updateAvailable: false
      }
    },

    mounted () {
      const self = this
      // Listen for Service worker "Update is available" event
      window.addEventListener('message', function (event) {
        if (event.data.action === 'updateAvailable') {
          self.appVersion = event.data.appVersion
          self.updateAvailable = true
        }
      })

      // Setup a listener to track Add to Homescreen events.
      window.addEventListener('beforeinstallprompt', e => {
        e.userChoice.then(choiceResult => {
          window.gtag.event('A2H', choiceResult.outcome)
        })
      })
    }
  })

  app.config.globalProperties.$user = {
    getUser () {
      if (process.env.SERVER) {
        return null
      }
      return JSON.parse(localStorage.getItem('user'))
    },

    isLoggedIn () {
      return this.getUser()
      // return null
    },

    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
    }
  }

  // Add error dialog helper
  app.config.globalProperties.$errorDialog = function (errorMessage) {
    this.$q.dialog({
      title: 'Error',
      message: errorMessage
    })
  }

  // Detect if app is running in production mode
  app.config.globalProperties.$isProd = function () {
    return process.env.NODE_ENV === 'production'
  }
})

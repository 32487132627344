import { createI18n } from 'vue-i18n'
import { LocalStorage } from 'quasar'
import messages from 'src/i18n'

export default ({ app }) => {
  // Create I18n instance
  const i18n = createI18n({
    locale: LocalStorage.getItem('locale') || Intl?.DateTimeFormat()?.resolvedOptions()?.locale?.toLowerCase(),
    fallbackLocale: 'en-US',
    messages
  })

  // Tell app to use the I18n instance
  app.use(i18n)
}

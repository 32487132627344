import * as Sentry from '@sentry/vue'

import { boot } from 'quasar/wrappers'

export default boot(async ({ app }) => {
  Sentry.init({
    app,
    dsn: 'https://caef15c6a3974d149faa1aa7d0425e63@sentry.io/1380809',
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'runCustomize is not defined',
      'hasInject is not defined'
    ],
    allowUrls: [
      'https://videodownloaderbot.com'
    ]
  })

  // Make sentry available in all components
  app.config.globalProperties.$sentry = Sentry
})

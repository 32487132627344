import enUS from './translations/en.json'
import ptBr from './translations/pt-br.json'
import ja from './translations/ja.json'
import enNG from './translations/en-ng.json'
import de from './translations/de.json'
import ZhCN from './translations/zh-CN.json'
import fr from './translations/fr.json'
import es from './translations/es.json'

export default {
  'en-US': enUS,
  'pt-BR': ptBr,
  'ja': ja,
  'en-NG': enNG,
  'de': de,
  'zh-CN': ZhCN,
  'fr': fr,
  'es': es
}

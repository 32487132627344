// src/boot/axios.js

import { boot } from 'quasar/wrappers'
import axios from 'axios'
import axiosRetry from 'axios-retry'

const BASE_URL = process.env.BASE_URL
const api = axios.create({
  baseURL: BASE_URL
})

axiosRetry(api, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios
})

export { api, axios }

import firebase from 'firebase/app'
import 'firebase/messaging'

import { boot } from 'quasar/wrappers'

// "async" is optional;
// more info on params: https://v2.quasar.dev/quasar-cli/boot-files
export default boot(async (/* { app, router, ... } */) => {
  // Initialize firebase
  const config = {
    apiKey: 'AIzaSyCbFPz8wEJUgFRB27u0RiqSBcdIkphaRj0',
    authDomain: 'downloaderbot-10.firebaseapp.com',
    databaseURL: 'https://downloaderbot-10.firebaseio.com',
    projectId: 'downloaderbot-10',
    storageBucket: 'downloaderbot-10.appspot.com',
    messagingSenderId: '665287203310'
  }

  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }

  /*
  Recive foreground push notifications
  firebase.messaging().onMessage((payload) => {
    console.log('Message received. ', payload)
  })
  */
})

/* global gtag */

import { boot } from 'quasar/wrappers'

export default boot(async ({ router }) => {
  gtag('set', 'appId', 'com.video.downloader.bot')
  gtag('set', 'appName', 'Video Downloader Bot')
  gtag('set', 'appVersion', localStorage.getItem('app_version'))

  // Track events with google analytics (Gtag)
  router.afterEach(to => {
    gtag('config', 'UA-132760445-1', {
      page_path: to.path,
      page_title: to.name,
      page_location: window.location.href
    })
  })
})

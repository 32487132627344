/*
 * This file is picked up by the build system only
 * when building for PRODUCTION
 */

import { register } from 'register-service-worker'
import { axios } from '../src/boot/axios'

import firebase from 'firebase/app'

axios.get('https://videodownloaderbot.com/ver').then(response => {
  const appVersion = response.data

  const localAppVersion = localStorage.getItem('app_version')
  if (appVersion !== localAppVersion) {
    window.postMessage({ action: 'updateAvailable', appVersion: appVersion })
  }
})

register(process.env.SERVICE_WORKER_FILE, {
  ready () {
    console.log('App is being served from cache by a service worker.')
  },
  registered (registration) { // registration -> a ServiceWorkerRegistration instance
    console.log('Service worker has been registered.')
    // Register firebase (Firebase variable is registered in index.html)
    const messaging = firebase.messaging()
    messaging.useServiceWorker(registration)
    console.log('Firebase has been registered!')
  },
  cached (registration) { // registration -> a ServiceWorkerRegistration instance
    console.log('Content has been cached for offline use.')
  },
  updatefound (registration) { // registration -> a ServiceWorkerRegistration instance
    console.log('New content is downloading.')

    // An updated service worker has appeared in reg.installing!
    var newWorker = registration.installing
    newWorker.addEventListener('statechange', () => {
      // Has service worker state changed?
      switch (newWorker.state) {
        case 'installed':
          // // There is a new service worker available, show the notification
          // window.postMessage({ action: 'updateAvailable' })
          break
      }
    })
  },
  updated (registration) { // registration -> a ServiceWorkerRegistration instance
    // window.postMessage({ action: 'updateAvailable' })
    console.log('New content is available; please refresh.')
  },
  offline () {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error (err) {
    console.error('Error during service worker registration:', err)
  }
})

// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

// Listen for updateApp event
window.addEventListener('message', (event) => {
  if (event.data.action === 'updateApp') {
    caches.keys().then(function (names) {
      for (let name of names) {
        caches.delete(name)
      }

      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          registrations.forEach(registration => {
            registration.unregister()
          })
          localStorage.setItem('app_version', event.data.appVersion)
          window.location.reload(true)
        })
    })
  }
})

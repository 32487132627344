
const routes = [
  {
    path: '/:language?',
    component: () => import('layouts/PublicLayout.vue'),
    children: [
      { path: '', name: 'Public', component: () => import('pages/Public.vue'), meta: { 'no_auth': true, platform: 'DEFAULT' } },
      { path: 'tiktok', name: 'TikTok', component: () => import('pages/Public.vue'), meta: { 'no_auth': true, platform: 'TIKTOK' } },
      { path: 'facebook', name: 'Facebook', component: () => import('pages/Public.vue'), meta: { 'no_auth': true, platform: 'FACEBOOK' } },
      { path: 'youtube', name: 'Youtube', component: () => import('pages/Public.vue'), meta: { 'no_auth': true, platform: 'YOUTUBE' } },
      { path: 'twitter', name: 'Twitter', component: () => import('pages/Public.vue'), meta: { 'no_auth': true, platform: 'TWITTER' } },

      { path: 'intro', name: 'Intro', component: () => import('pages/Intro.vue'), meta: { 'no_auth': true } },
      { path: 'login', name: 'Login', component: () => import('pages/Login.vue'), meta: { 'no_auth': true } },
      { path: 'register', name: 'Register', component: () => import('pages/Register.vue'), meta: { 'no_auth': true } },
      { path: 'reset-password', name: 'ResetPassword', component: () => import('pages/ResetPassword.vue'), meta: { 'no_auth': true } }
    ]
  },
  {
    path: '/home',
    component: () => import('layouts/PrivateLayout.vue'),
    children: [
      { path: '', name: 'Home', component: () => import('pages/Index.vue') },
      { path: '/share-target', name: 'ShareTarget', component: () => import('pages/ShareTarget.vue') },
      { path: '/downloads/:username', name: 'UserDownloads', component: () => import('pages/UserDownloads'), meta: { 'no_auth': true } }
    ]
  }

  // Always leave this as last one,
  // but you can also remove it if not using SSR
  /* {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  } */
]

export default routes
